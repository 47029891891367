import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import Badge from "../../Badge";
import React from "react";

const AssetBoardColumnItem = (props) => {
    return (
        <Card id={'board_item_' + props.asset.id}
              className={'shadow-md border mt-3'}
              draggable={true}
              onDragStart={props.dragStartHandler}>
            <CardBody>
                <div className={'flex justify-between'}>
                    <span className={'cursor-pointer underline'}
                          onClick={() => props.openAssetPage(props.asset)}>
                        {props.asset.name}
                    </span>
                    <Badge title={props.asset.type} classes={'bg-gray-400 text-white'}/>
                </div>
            </CardBody>
        </Card>
    )
}

export default AssetBoardColumnItem;