import React from "react";
import AssetBoardColumn from "./AssetBoardColumn";

const AssetBoard = (props) => {

    const dragStartHandler = (ev) => {
        ev.dataTransfer.setData("application/my-app", ev.target.id);
        ev.dataTransfer.effectAllowed = "move";
    };

    const dragOverHandler = (ev) => {
        ev.preventDefault();
        ev.dataTransfer.dropEffect = "move";
    };

    const dropHandler = (ev) => {
        ev.preventDefault();
        const data = ev.dataTransfer.getData("application/my-app");

        const srcElement = document.getElementById(data);
        const dstElement = ev.target;

        // Find the source tag
        const srcTagId = parseInt(srcElement.parentNode.parentNode.id.replace('board_column_', ''));
        const srcTag = props.tags.filter((tag) => tag.id === srcTagId)[0];

        // Find the asset
        const assetId = data.replace('board_item_', '');
        const asset = props.assets[srcTagId].filter((asset) => asset.id === assetId)[0];

        // Find the dest tag
        const dstTagId = parseInt(dstElement.parentNode.id.replace('board_column_', ''));
        const dstTag = props.tags.filter((tag) => tag.id === dstTagId)[0];

        // Call the parent
        props.onMoveAsset(asset, srcTag, dstTag);

        // Append in the HTML the changes
        dstElement.appendChild(srcElement);
    };

    return (
        <div className={'flex gap-4 ' + (props.className ?? '')}>
            {(props.tags ?? []).map((tag) => (
                <AssetBoardColumn
                    key={tag.id}
                    tag={tag}
                    assets={props.assets[tag.id] ?? []}
                    dragStartHandler={dragStartHandler}
                    dragOverHandler={dragOverHandler}
                    dropHandler={dropHandler}
                    openAssetPage={props.openAssetPage}/>
            ))}
        </div>
    )
}

export default AssetBoard;