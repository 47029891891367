import Card from "../../Card/Card";
import Tag from "../../Tag/Tag";
import CardBody from "../../Card/CardBody";
import React from "react";
import AssetBoardColumnItem from "./AssetBoardColumnItem";

const AssetBoardColumn = (props) => {
    return (
        <Card id={'board_column_' + props.tag.id}
              className={'grow'}
              onDragOver={props.dragOverHandler}
              onDrop={props.dropHandler}>
            <div className="px-4 py-5 sm:px-6 flex justify-between">
                <Tag group={props.tag.group} name={props.tag.name} classes={'text-white'} color={props.tag.color}/>
                <span
                    className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-200 text-gray-800">
                    {props.assets.length}
                </span>
            </div>
            <CardBody>
                {props.assets.map((asset) => (
                    <AssetBoardColumnItem key={asset.id}
                                          asset={asset}
                                          dragStartHandler={props.dragStartHandler}
                                          openAssetPage={props.openAssetPage}/>
                ))}
            </CardBody>
        </Card>
    )
}

export default AssetBoardColumn;