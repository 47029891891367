import {useParams} from "react-router-dom";
import AssetPage from "../components/Asset/AssetPage/AssetPage";
import {useCallback, useState} from "react";
import RecordTable from "../components/Record/RecordTable";
import ServiceTable from "../components/Service/ServiceTable";
import IssueTable from "../components/Host/Issue/IssueTable";

export default function Host(props) {
    const params = useParams();
    const initialAsset = {
        asset: {
            id: '',
            name: '',
            tags: [],
        }
    };

    const [description, setDescription] = useState({});
    const [records, setRecords] = useState([]);
    const [services, setServices] = useState([]);
    const [issues, setIssues] = useState([]);

    const onUpdateAsset = useCallback((asset) => {
        setRecords(asset.records ?? []);
        setServices(asset.services ?? []);
        setIssues(asset.raw_asset.issues ?? []);

        setDescription({
            'country': asset.autonomous_system.country,
            'AS': asset.autonomous_system.owner + ' (' + asset.autonomous_system.id + ')',
        });
    }, []);

    return (
        <AssetPage workspaceId={params.workspace}
                   assetType={'hosts'}
                   assetId={params.host}
                   initialAsset={initialAsset}
                   onUpdateAsset={onUpdateAsset}
                   description={description}>
            <div className='col-span-12 lg:col-span-6'>
                <RecordTable records={records} className={'max-h-80'}/>
            </div>
            <div className='col-span-12 lg:col-span-6'>
                <ServiceTable services={services} className={'max-h-80'}/>
            </div>
            <div className='col-span-12'>
                <IssueTable issues={issues} className={'max-h-80'}/>
            </div>
        </AssetPage>
    )
}
