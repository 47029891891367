import {useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import Page from "../../components/Page/Page";
import PageHeader from "../../components/Page/PageHeader";
import {
    deleteWorkspace,
    getWorkspace,
    getWorkspaceAssetMap,
    getWorkspaceAssets,
    getWorkspaceStatistics,
    getWorkspaceTags,
    getWorkspaceThreats,
    handleDisconnect,
    refreshWorkspace,
    setAssetTags
} from "../../requests";
import AppContext from "../../store/app-context";
import WorkspaceDeleteModal from "../../components/Workspace/WorkspaceDeleteModal";
import WorkspaceRefreshModal from "../../components/Workspace/WorkspaceRefreshModal";
import WorkspacePageTitle from "../../components/Page/WorkspacePageTitle";
import {buildCalendar} from "../../calendar";
import {buildAssetMap} from "../../components/Asset/AssetMap/asset-map";
import {defaultAssetFilters} from "../../components/Asset/AssetFilter/asset-filter";
import WorkspaceMenuView from "../../components/Workspace/WorkspaceMenu/WorkspaceMenuView";
import WorkspaceMenuActions from "../../components/Workspace/WorkspaceMenu/WorkspaceMenuActions";
import WorkspaceViewCard from "../../components/Workspace/WorkspaceView/WorkspaceViewCard";
import WorkspaceViewMap from "../../components/Workspace/WorkspaceView/WorkspaceViewMap";
import WorkspaceViewBoard from "../../components/Workspace/WorkspaceView/WorkspaceViewBoard";

function filterThreats(threat) {
    return threat.muted !== undefined ? threat.muted === false :
        !threat.assets || threat.assets.some((a) => a['muted'] === undefined || a['muted'] === false)
}

function openAssetPage(workspaceId, asset) {
    if (asset.type === 'record' || asset.type === 'autonomous_system') {
        return;
    }

    const url = '/workspaces/' + workspaceId + '/' + asset.type + 's/' + asset.id;
    window.open(url, '_blank');
}

// eslint-disable-next-line no-unused-vars
function buildReportCalendar(date, reports, reportType) {
    const calendar = buildCalendar(date.getFullYear(), date.getMonth() + 1)

    const days = [];
    let totalCount = 0;

    for (let day of calendar.days) {
        const parts = day.date.split('-');
        day.count = 0;

        for (const report of reports.filter((report) => report.type === reportType)) {
            const startedAt = new Date(new Date(report.started_at).setHours(0, 0, 0));
            const endedAt = new Date(new Date(report.ended_at).setHours(23, 59, 59));

            const calendarDate = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));

            if (calendarDate >= startedAt && calendarDate <= endedAt) {
                day.count = day.count + report.items;
                totalCount = totalCount + day.count;
            }
        }
        days.push(day);
    }

    return {
        days: days,
        count: totalCount
    };
}

export default function WorkspacesView() {
    const appContext = useContext(AppContext);
    const params = useParams();
    const navigate = useNavigate();

    const [workspace, setWorkspace] = useState({
        id: '',
        name: '',
        description: '',
        role: ''
    });

    const [threats, setThreats] = useState([]);
    const [assets, setAssets] = useState([]);
    const [pages, setPages] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showRefreshModal, setShowRefreshModal] = useState(false);

    const [viewType, setViewType] = useState('card');

    const [assetMap, setAssetMap] = useState({
        'nodes': [],
        'links': [],
    });
    const [isLoadingAssetMap, setIsLoadingAssetMap] = useState(false);

    const [assetMapFilters, setAssetMapFilters] = useState(defaultAssetFilters);
    const [assetListFilters, setAssetListFilters] = useState(defaultAssetFilters);
    const [isLoadingAssets, setIsLoadingAssets] = useState(true);

    // eslint-disable-next-line no-unused-vars
    const [changesCalendar, setChangesCalendar] = useState();
    // eslint-disable-next-line no-unused-vars
    const [threatsCalendar, setThreatsCalendar] = useState();

    const [workspaceStatistics, setWorkspaceStatistics] = useState({});

    const [tags, setTags] = useState([]);

    const onDeleteWorkspace = () => {
        setShowDeleteModal(false);
        deleteWorkspace(appContext.token, workspace.id)
            .then(() => {
                navigate('/');
            })
            .catch((error) => handleDisconnect(error, appContext));
    };

    const onRefreshWorkspace = () => {
        refreshWorkspace(appContext.token, workspace.id).then(() => {
            setShowRefreshModal(true);
        });
    }

    const switchViewType = (viewType) => {
        setViewType(viewType);
    };

    const updateAssetMapFilters = (assetMapFilters) => {
        appContext.updatePreferences({
            ...appContext.preferences,
            assetMapFilters: {
                ...appContext.preferences.assetMapFilters,
                [workspace.id]: assetMapFilters,
            }
        });
        setAssetMapFilters(assetMapFilters);
    };

    const updateAssetListFilters = (assetListFilters) => {
        appContext.updatePreferences({
            ...appContext.preferences,
            assetListFilters: {
                ...appContext.preferences.assetListFilters,
                [workspace.id]: assetListFilters,
            }
        });
        setAssetListFilters(assetListFilters);
    }

    const onMoveAsset = (asset, fromTag, toTag) => {
        // Compute list of new tag for the asset
        const newTags = asset.tags.filter((tag) => tag.id !== fromTag.id);
        newTags.push(toTag);

        const newTagIds = newTags.map((tag) => tag.id);

        setAssetTags(appContext.token, params.workspace, asset.type + 's', asset.id, newTagIds).then(() => {
            getWorkspace(appContext.token, params.workspace)
                .then((workspace) => {
                    setWorkspace(workspace);
                    setPages([
                        {id: 1, name: 'Workspaces', href: '/workspaces', current: false},
                        {id: 2, name: workspace.name, href: '/workspaces/' + workspace.id, current: true},
                    ]);
                })
                .catch((error) => handleDisconnect(error, appContext));
        });
    }

    useEffect(() => {
        const preferences = appContext.preferences;
        let assetMapFilters = preferences?.assetMapFilters?.[workspace.id] ?? defaultAssetFilters;
        let assetListFilters = preferences?.assetListFilters?.[workspace.id] ?? {
            ...defaultAssetFilters,
            assetTypes: {
                ...defaultAssetFilters.assetTypes,
                record: {
                    ...defaultAssetFilters.assetTypes.record,
                    isEnabled: false,
                },
                autonomous_system: {
                    ...defaultAssetFilters.assetTypes.autonomous_system,
                    isEnabled: false,
                }
            }
        };

        //Add tags in filters
        let assetTags = {};

        tags.forEach((tag) => {
            assetTags[tag.id] = {
                label: tag.name,
                isEnabled: false,
            }
        })

        assetMapFilters.assetTags = {...assetTags, ...assetMapFilters.assetTags};
        assetListFilters.assetTags = {...assetTags, ...assetListFilters.assetTags};

        setAssetMapFilters(assetMapFilters);
        setAssetListFilters(assetListFilters);
    }, [appContext, tags, workspace.id]);

    useEffect(() => {
        const isDisplayingMap = viewType === 'map' && appContext.user.features.includes('WORKSPACE_VIEW_MAP');
        if (isDisplayingMap) {
            setIsLoadingAssetMap(true);

            getWorkspaceAssetMap(appContext.token, params.workspace)
                .then((assetMap) => buildAssetMap(assetMap, assetMapFilters))
                .then(setAssetMap)
                .then(() => setIsLoadingAssetMap(false));
        }

    }, [params.workspace, appContext.token, appContext.user.features, viewType, assetMapFilters]);

    useEffect(() => {
        getWorkspace(appContext.token, params.workspace)
            .then((workspace) => {
                setWorkspace(workspace);

                setPages([
                    {id: 1, name: 'Workspaces', href: '/workspaces', current: false},
                    {id: 2, name: workspace.name, href: '/workspaces/' + workspace.id, current: true},
                ]);
            })
            .catch((error) => handleDisconnect(error, appContext));


        getWorkspaceThreats(appContext.token, params.workspace)
            .then((threats) => setThreats(threats.filter(filterThreats)))
            .catch((error) => handleDisconnect(error, appContext));
        getWorkspaceAssets(appContext.token, params.workspace)
            .then((assets) => {
                setAssets(assets.map((asset) => {
                    return {
                        ...asset,
                        threats: asset.threats.filter(filterThreats),
                    }
                }));
                setIsLoadingAssets(false);
            })
            .catch((error) => handleDisconnect(error, appContext));

        // getWorkspaceReports(appContext.token, params.workspace).then((reports) => {
        //     const today = new Date();
        //     const calendarConfigs = [
        //         {reportType: 'asset', setCalendar: setChangesCalendar},
        //         {reportType: 'threat', setCalendar: setThreatsCalendar},
        //     ];
        //
        //     for (const calendarConfig of calendarConfigs) {
        //         const calendar = buildCalendar(today.getFullYear(), today.getMonth() + 1)
        //
        //         calendarConfig.setCalendar({
        //             ...calendar,
        //             ...buildReportCalendar(today, reports, calendarConfig.reportType)
        //         })
        //     }
        // });

        getWorkspaceStatistics(appContext.token, params.workspace).then(setWorkspaceStatistics);

        getWorkspaceTags(appContext.token, params.workspace).then(setTags)

    }, [appContext, params.workspace, appContext.token]);

    return (
        <Page>
            <PageHeader pages={pages}>
                <WorkspacePageTitle title={workspace.name} isSecured={workspace.is_secured}/>
                <WorkspaceMenuView switchViewType={switchViewType} features={appContext.user.features}/>
                <WorkspaceMenuActions workspaceId={workspace.id}
                                      workspaceRole={workspace.role}
                                      features={appContext.user.features}
                                      onRefreshWorkspace={onRefreshWorkspace}
                                      setShowDeleteModal={setShowDeleteModal}/>
            </PageHeader>
            {
                (viewType === 'card' &&
                    <WorkspaceViewCard workspaceId={workspace.id}
                                       statistics={workspaceStatistics}
                                       assets={assets}
                                       threats={threats}
                                       changesCalendar={changesCalendar}
                                       threatsCalendar={threatsCalendar}
                                       assetListFilters={assetListFilters}
                                       updateAssetListFilters={updateAssetListFilters}
                                       isLoadingAssets={isLoadingAssets}/>)
                || (viewType === 'map' &&
                    <WorkspaceViewMap isLoadingAssetMap={isLoadingAssetMap}
                                      assetMap={assetMap}
                                      assetMapFilters={assetMapFilters}
                                      updateAssetMapFilters={updateAssetMapFilters}
                                      openAssetPage={openAssetPage}
                                      workspaceId={workspace.id}/>)
                || (viewType === 'board' &&
                    <WorkspaceViewBoard workspaceId={workspace.id}
                                        assets={assets}
                                        isLoadingAssets={isLoadingAssets}
                                        tags={tags}
                                        openAssetPage={openAssetPage}
                                        onMoveAsset={onMoveAsset}/>)
            }
            <WorkspaceRefreshModal isOpen={showRefreshModal} setOpen={setShowRefreshModal}/>
            <WorkspaceDeleteModal isOpen={showDeleteModal} setOpen={setShowDeleteModal} onDelete={onDeleteWorkspace}/>
        </Page>
    )
}
