const getDescription = (issue) => {
    switch (issue.issue) {
        case 'IMPERSONATION':
            return issue.context.helo;
        default:
            return '';
    }
}

const IssueTableItem = (props) => {
    return (
        <tr>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {props.issue}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {getDescription(props)}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {props.first_seen}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {props.last_seen}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {props.valid_until}
            </td>
        </tr>
    )
}

export default IssueTableItem;
