import {Navigate, Route, Routes} from "react-router-dom";
import Workspaces from "../routes/workspaces";
import WorkspacesCreate from "../routes/workspaces/create";
import WorkspacesView from "../routes/workspaces/view";
import WorkspacesEdit from "../routes/workspaces/edit";
import WorkspacesMembers from "../routes/workspaces/members";
import WorkspacesReports from "../routes/workspaces/reports";
import WorkspaceReportsView from "../routes/workspaces/reports/view";
import WorkspacesAssets from "../routes/workspaces/assets";
import WorkspacesActivities from "../routes/workspaces/activities";
import Domain from "../routes/domain";
import Certificate from "../routes/certificate";
import Host from "../routes/host";
import Service from "../routes/service";
import Website from "../routes/website";
import Email from "../routes/email";
import Teams from "../routes/teams";
import TeamsView from "../routes/teams/view";
import Settings from "../routes/settings";
import {useState} from "react";
import Navbar from "../components/Navbar/Navbar";
import CommandPalette from "../components/CommandPalette";
import WorkspacesTags from "../routes/workspaces/tags";
import Subnet from "../routes/subnet";

const LoggedInRoutes = () => {
    return (
        <Routes>
            <Route path="/" exact element={<Navigate replace to="/workspaces"/>}/>
            <Route path="/workspaces" exact element={<Workspaces/>}/>
            <Route path="/workspaces/create" exact element={<WorkspacesCreate/>}/>
            <Route path="/workspaces/:workspace" exact element={<WorkspacesView/>}/>
            <Route path="/workspaces/:workspace/edit" exact element={<WorkspacesEdit/>}/>
            <Route path="/workspaces/:workspace/members" exact element={<WorkspacesMembers/>}/>
            <Route path="/workspaces/:workspace/reports" exact element={<WorkspacesReports/>}/>
            <Route path="/workspaces/:workspace/reports/:report" exact element={<WorkspaceReportsView/>}/>
            <Route path="/workspaces/:workspace/assets" exact element={<WorkspacesAssets/>}/>
            <Route path="/workspaces/:workspace/activities" exact element={<WorkspacesActivities/>}/>
            <Route path="/workspaces/:workspace/tags" exact element={<WorkspacesTags/>}/>
            <Route path="/workspaces/:workspace/domains/:domain" exact element={<Domain/>}/>
            <Route path="/workspaces/:workspace/certificates/:certificate" exact element={<Certificate/>}/>
            <Route path="/workspaces/:workspace/hosts/:host" exact element={<Host/>}/>
            <Route path="/workspaces/:workspace/services/:service" exact element={<Service/>}/>
            <Route path="/workspaces/:workspace/websites/:website" exact element={<Website/>}/>
            <Route path="/workspaces/:workspace/emails/:email" exact element={<Email/>}/>
            <Route path="/workspaces/:workspace/subnets/:subnet" exact element={<Subnet/>}/>
            <Route path="/teams" exact element={<Teams/>}/>
            <Route path="/teams/:team" exact element={<TeamsView/>}/>
            <Route path="/settings" exact element={<Settings/>}/>
            <Route path="*" exact element={<Navigate replace to="/workspaces"/>}/>
        </Routes>
    )
}

const LoggedInRouter = (props) => {
    const [commandOpen, setCommandOpen] = useState(false);

    const isGlobalSearchAllowed = props.user != null && props.user.features.includes('GLOBAL_SEARCH');

    return (
        <>
            <Navbar setCommandOpen={setCommandOpen} logout={props.logout}/>
            {isGlobalSearchAllowed ? <CommandPalette open={commandOpen} setOpen={setCommandOpen}/> : null}
            <LoggedInRoutes/>
        </>
    )
}

export default LoggedInRouter;
