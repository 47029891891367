function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Card = (props) => {
    return (
        <div id={props.id ?? null} key={props.key} ref={props.childRef}
            className={classNames("flex flex-col bg-white shadow rounded-lg divide-y divide-gray-200", props.className ?? "")}
            style={props.style ?? {}}
            draggable={props.draggable ?? false}
            onDragStart={props.onDragStart ?? null}
            onDragOver={props.onDragOver ?? null}
            onDrop={props.onDrop ?? null}>
            {props.children}
        </div>
    );
}

export default Card;
