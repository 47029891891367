import {EyeIcon} from "@heroicons/react/outline";
import {MapIcon, TemplateIcon, ViewBoardsIcon} from "@heroicons/react/solid";
import React from "react";
import Dropdown from "../../UI/Dropdown/Dropdown";
import DropdownButton from "../../UI/Dropdown/DropdownButton";
import DropdownItems from "../../UI/Dropdown/DropdownItems";
import DropdownItem from "../../UI/Dropdown/DropdownItem";
import DropdownItemText from "../../UI/Dropdown/DropdownItemText";

const WorkspaceMenuView = (props) => {
    const viewTypes = [
        {type: 'card', title: 'Card', icon: TemplateIcon},
        {type: 'map', title: '2D Map', icon: MapIcon, feature: 'WORKSPACE_VIEW_MAP'},
        {type: 'board', title: 'Board', icon: ViewBoardsIcon, feature: 'WORKSPACE_VIEW_BOARD'},
    ]

    const filterViewType = (viewType) => viewType.feature === undefined || props.features.includes(viewType.feature);

    return (
        <Dropdown className={'mr-2'}>
            <DropdownButton title={'View'} icon={<EyeIcon className="h-5 w-5" aria-hidden="true"/>}/>
            <DropdownItems>
                <div className={'py-1'}>
                    {viewTypes.filter(filterViewType).map((viewType) => (
                        <DropdownItem key={viewType.type}
                                      onClick={() => props.switchViewType(viewType.type)}
                                      className={'cursor-pointer'}>
                            <DropdownItemText title={viewType.title}
                                              icon={<viewType.icon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true"/>}/>
                        </DropdownItem>
                    ))}
                </div>
            </DropdownItems>
        </Dropdown>
    )
}

export default WorkspaceMenuView;
