import IssueTableItem from "./IssueTableItem";
import Table from "../../Table/Table";

const IssueTable = (props) => {
    const issues = (props.issues ?? []).map((issue, i) => {
        return {
            tableKey: i,
            ...issue
        };
    });

    return (
        <Table className={props.className}
               title={'Issues'}
               keys={['Code', 'Description', 'First seen', 'Last seen', 'Valid until']}
               items={issues}
               tableItem={IssueTableItem}/>
    )
}

export default IssueTable;
