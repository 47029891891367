import React, {useCallback, useState} from "react";
import AssetPage from "../components/Asset/AssetPage/AssetPage";
import {useParams} from "react-router-dom";
import HostTable from "../components/Host/HostTable";

export default function Subnet() {
    const params = useParams();
    const initialAsset = {
        asset: {
            id: '',
            name: '',
            tags: [],
        }
    };

    const [description, setDescription] = useState({});
    const [hosts, setHosts] = useState([]);

    const onUpdateAsset = useCallback((asset) => {
        setHosts(asset.hosts ?? []);

        setDescription({
            name: asset.asset.name,
        });
    }, []);

    const assetDisplayFn = useCallback((asset) => {
        return asset.asset.name;
    }, []);

    return (
        <AssetPage workspaceId={params.workspace}
                   assetType={'subnets'}
                   assetId={params.subnet}
                   initialAsset={initialAsset}
                   onUpdateAsset={onUpdateAsset}
                   description={description}
                   assetDisplayFn={assetDisplayFn}>
            <HostTable hosts={hosts} className={'col-span-12'}/>
        </AssetPage>
    )
}
