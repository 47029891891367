import React, {useEffect, useState} from "react";
import InputLabel from "../../UI/Inputs/InputLabel";
import InputSelect from "../../UI/Inputs/InputSelect";
import InputSelectOption from "../../UI/Inputs/InputSelectOption";
import AssetBoard from "../../Asset/AssetBoard/AssetBoard";

const WorkspaceViewBoard = (props) => {
    const [groupedTags, setGroupedTags] = useState({});
    const [groupedAssets, setGroupedAssets] = useState({});
    const [selectedGroup, setSelectedGroup] = useState('');

    useEffect(() => {
        setGroupedTags(props.tags.reduce((acc, cur) => {
            if (!(cur.group in acc)) {
                acc[cur.group] = [];
            }

            acc[cur.group].push(cur);

            return acc;
        }, {}));
    }, [props.tags]);

    useEffect(() => {
        if (Object.keys(groupedTags).length > 0) {
            setSelectedGroup(Object.keys(groupedTags)[0]);
        }

    }, [groupedTags]);

    useEffect(() => {
        setGroupedAssets(props.assets.reduce((acc, cur) => {
            for (const tag of cur['tags'] ?? []) {
                if (!(tag.id in acc)) {
                    acc[tag.id] = [];
                }

                acc[tag.id].push(cur);
            }

            return acc;
        }, {}));
    }, [groupedTags, props.assets])


    return (
        <>
            <div className={'w-1/4'}>
                <InputLabel htmlFor={'tag_group'} title={'Tag group'}/>
                <InputSelect id="tag_group" name="tag_group" className={'mt-1'}
                             onChange={(e) => setSelectedGroup(e.target.value)} value={selectedGroup}>
                    {(Object.keys(groupedTags) ?? []).map((tagGroup) => (
                        <InputSelectOption value={tagGroup} title={tagGroup}/>
                    ))}
                </InputSelect>
            </div>

            <AssetBoard tags={groupedTags[selectedGroup]}
                        assets={groupedAssets}
                        className={'mt-5'}
                        onMoveAsset={props.onMoveAsset}
                        openAssetPage={(asset) => props.openAssetPage(props.workspaceId, asset)}/>
        </>
    )
}

export default WorkspaceViewBoard;
